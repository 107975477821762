import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, inject, provideAppInitializer } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';

import { UserIdleModule, UserIdleConfig } from 'angular-user-idle';

// LC Modules
import { InputsModule, NavigationMenuModule } from '@lc/ui';
import { SharedComponentsUiModule } from '@lc/shared-components-ui';
import {
  LayoutWithoutSidenavComponent,
  SharedComponentsLayoutModule,
} from '@lc/shared-components-layout';
import {
  AuthGuard,
  CoreModule,
  StateModule,
  initializeApp,
  StartupService,
  AppService,
  LaunchDarklyService,
  DataDogLogger,
  CloseDialogResolve,
} from '@lc/core';

import {
  SharedComponentsAuthModule,
  OktaLoginComponent,
  OktaCallbackComponent,
  LogoutComponent,
} from '@lc/shared-components-auth';

// Internal Views
import { AppComponent } from './app.component';

// Internal Components
import { AdminNavigationComponent } from './components/admin-navigation/admin-navigation.component';

const angularModules = [
  BrowserModule,
  BrowserAnimationsModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
];
const lcModules = [
  NavigationMenuModule,
  CoreModule.forRoot(),
  InputsModule,
  SharedComponentsUiModule.forRoot(),
  SharedComponentsAuthModule,
  SharedComponentsLayoutModule,
];
const internalViews = [AppComponent];
const internalComponents = [AdminNavigationComponent];

if (location.hostname !== 'localhost' && location.hostname !== 'remotedev') {
  DataDogLogger.initialize('pub3e0ad424f80c44717b7da7ab908bb3f9');
  DataDogLogger.initializeDDRealUserMonitoring('pub3e0ad424f80c44717b7da7ab908bb3f9');
}

@NgModule({
  declarations: [...internalViews, ...internalComponents],
  imports: [
    ...angularModules,
    ...lcModules,
    StateModule,
    RouterModule.forRoot([
      {
        // NOTE: These routes require AUTHENTICATION
        path: '',
        component: LayoutWithoutSidenavComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
          {
            path: '',
            component: AdminNavigationComponent,
            children: [
              { path: '', loadChildren: () => import('./admin-dashboard/admin-dashboard.module').then((m) => m.AdminDashboardModule) },
              { path: 'photography', loadChildren: () => import('./photography-agency/photography-agency.module').then((m) => m.PhotographyAgencyModule) },
              { path: 'orders', loadChildren: () => import('./orders/orders.module').then((m) => m.OrdersModule) },
              { path: 'users', loadChildren: () => import('./users/users.module').then((m) => m.UsersModule) },
              { path: 'templates', loadChildren: () => import('./templates/templates.module').then((m) => m.TemplatesModule) },
              { path: 'emailtemplates', loadChildren: () => import('./email-templates/email-templates.module').then((m) => m.EmailTemplatesModule) },
              { path: 'indesign', loadChildren: () => import('./indesign/plugin/plugin.module').then((m) => m.PluginModule) },
              { path: 'regeneratepdf', loadChildren: () => import('./regenerate-pdf/regenerate-pdf.module').then((m) => m.RegeneratePdfModule) },
              { path: 'pages', loadChildren: () => import('@lc/ui').then((m) => m.UiModule) },
              { path: 'data-setup', loadChildren: () => import('./offerings/views/views.module').then((m) => m.OfferingViewsModule) },
              { path: 'surveys', loadChildren: () => import('./surveys/surveys.module').then((m) => m.SurveysModule) },
              { path: 'store', loadChildren: () => import('./store/store.module').then((m) => m.StoreModule) },

              { path: 'reporting', loadChildren: () => import('./reporting/views/views.module').then((m) => m.ViewsModule) },
            ],
          },
        ],
      },
      {
        // NOTE: These are the UNAUTHENTICATED routes that do not require authentication.
        // These routes do not use the LayoutWithoutSidenav due to the sidenav making
        // API requests. We only need an empty container anyways, as the toolbar never
        // shows for the logged out routes
        path: '',
        resolve: { closeDialogs: CloseDialogResolve },
        children: [
          { path: 'okta/login', component: OktaLoginComponent },
          { path: 'login/callback', component: OktaCallbackComponent },
          { path: 'logout', component: LogoutComponent },
        ],
      },
    ], {
      enableTracing: false,
      paramsInheritanceStrategy: 'always', // This is required to allow nested modules to retrieve all query parameters (i.e.: 'id')
      scrollPositionRestoration: 'enabled',
    }),
    UserIdleModule,
  ],
  providers: [
    // User activity timeouts
    // Ping value is not used
    // Session timeout is pulled from AppSettings after load.  If not found default is 5 minutes
    {
      provide: UserIdleConfig,
      useFactory: () => ({ idle: AppService.get('sessionTimeout') * 60, timeout: 30, ping: 10000 }),
    },
    provideAppInitializer(() => {
      const initializerFn = (initializeApp)(inject(StartupService));
      return initializerFn();
    }),
    LaunchDarklyService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
